import { useEffect, useState } from "react";

const useWindowResize = (breakpoint = 768) => {
  const [mobile, setMobile] = useState(false);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    const checkWindowSize = () => {
      const isMobileSize = window.innerWidth <= breakpoint;
      setMobile(isMobileSize);
    };
    checkWindowSize();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return mobile;
};

export default useWindowResize;
