import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { uniqBy, find } from "lodash";
import BarChart from "../Charts/BarChart";
import { setAnalytics } from "../../store/actions/analyticsActions";
import { renderTitle } from "../Backlinks/utils";

const WeeklyRanksChart = ({lg=18, md= 12}) => {
  const dispatcher = useDispatch();
  const domain = useSelector((state) => state.domains.currentDomain);
  const analytics = useSelector((state) => state.analytics.analytics);
  const [keywordStatusWise, setkeywordStatusWise] = useState([]);

  useEffect(() => {
    if (domain) {
      dispatcher(setAnalytics(domain._id));
    }
  }, [domain]);

  useEffect(() => {
    if (analytics) {
      let weekStats = analytics.keywordWeeklyStats.map((stat) => {
        const down = find(stat.rankUpandDown, (upOrDown) => {
          return upOrDown._id.rankWise === "down";
        });
        const stable = find(stat.rankUpandDown, (upOrDown) => {
          return upOrDown._id.rankWise === "no_change";
        });
        const up = find(stat.rankUpandDown, (upOrDown) => {
          return upOrDown._id.rankWise === "up";
        });

        return {
          recordDate: moment
            .utc(stat.recordingDate.split("T")[0])
            .format("MMM Do YY"),
          Down: down !== undefined ? down.count : 0,
          DownColor: "hsl(322, 70%, 50%)",
          Stable: stable !== undefined ? stable.count : 0,
          StableColor: "hsl(278, 70%, 50%)",
          Up: up !== undefined ? up.count : 0,
          UpColor: "hsl(50, 70%, 50%)",
        };
      });
      weekStats = uniqBy(weekStats, "recordDate");
      weekStats = weekStats.slice(Math.max(weekStats.length - 8, 0));
      weekStats.pop();
      setkeywordStatusWise(weekStats);
    }
  }, [analytics]);

  return (
    <>
      <Col lg={lg} md={md} xs={24} sm={24} style={{ paddingRight: lg == 24 ? 0 : 15  }}>
        <BarChart
          indexBy="recordDate"
          dataKeys={["Down", "Stable", "Up"]}
          data={keywordStatusWise}
          title={renderTitle(
            "Weekly Rank Stats",
            "Weekly Rank Stats is a report that shows how your website's pages are ranking for specific keywords in Google search results over a period of one week. The report is updated weekly, so you can track your progress over time."
          )}
          scheme={{ scheme: "set1" }}
        />
      </Col>
    </>
  );
};

export default WeeklyRanksChart;
