import React from "react";
import { Row } from "antd";
import styled from 'styled-components';
import AnimatedBG from "./styles/AnimatedBG";

const NotSelected = ({ title }) => {
  const Content = styled.div`
    max-width: 400px;
    z-index: 2;
    min-width: 300px;
    h1 {
      font-size: 2rem;
    }
  `;

  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="bg-white text-center"
      style={{ minHeight: "88vh" }}
    >
      <AnimatedBG>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
          <span key={item} />
        ))}
      </AnimatedBG>
      <Content>
        <h1>{title}</h1>
      </Content>
    </Row>
  );
};

export default NotSelected;
