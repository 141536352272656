import axios from "axios";

import {
  SET_ANALYTICS
} from "./types";

export const setAnalytics = (domainId, type='domains') => (dispatch) => {

  axios
    .get(`${type}/${domainId}/analytics/overview`)
    .then((response) => {
      const analytics = response.data.analytics;
      dispatch({
        type: SET_ANALYTICS,
        payload: analytics,
      });
    }).catch(error => {} );
};