import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { sum, uniqBy } from "lodash";
import BarChart from "../Charts/BarChart";
import { setAnalytics } from "../../store/actions/analyticsActions";
import { renderTitle } from "../Backlinks/utils";

const SessionAnalytics = (props) => {
  const dispatcher = useDispatch();
  const domain = useSelector((state) => state.domains.currentDomain);
  const analytics = useSelector((state) => state.analytics.analytics);
  const [keywordStatusWise, setkeywordStatusWise] = useState([]);

  useEffect(() => {
    if (domain) {
      dispatcher(setAnalytics(domain._id));
    }
  }, [domain]);

  useEffect(() => {
    if (analytics) {
      let weekStats = analytics.keywordWeeklyStats.map((stat) => {
        return {
          recordDate: moment
            .utc(stat.recordingDate.split("T")[0])
            .format("MMMM Do YYYY"),
          "0-10": calculateRankCount(0, 10, stat.rankStats),
          "11-30": calculateRankCount(11, 30, stat.rankStats),
          "31-60": calculateRankCount(31, 60, stat.rankStats),
          "61-100": calculateRankCount(61, 100, stat.rankStats),
          ">100": calculateRankCount(100, 102, stat.rankStats),
        };
      });
      weekStats = uniqBy(weekStats, "recordDate");
      weekStats = weekStats.slice(Math.max(weekStats.length - 7, 0));
      setkeywordStatusWise(weekStats);
    }
  }, [analytics]);

  const calculateRankCount = (min, max, ranks) => {
    const collectRanks = ranks.map((rank) => {
      if (rank._id.rankWise > min && rank._id.rankWise <= max) {
        return rank.count;
      } else {
        return 0;
      }
    });
    return sum(collectRanks);
  };

  return (
    <>
      <Col span={24} style={{ paddingRight: "15px" }}>
        <BarChart
          title={renderTitle(
            "RankWise Stats",
            "The Limeclicks chart displays daily rank wise stats."
          )}
          indexBy="recordDate"
          dataKeys={["0-10", "11-30", "31-60", "61-100", ">100"]}
          data={keywordStatusWise}
          scheme={["#B2DF8A", "#61CDBB", "#EED314", "#E8C1A0", "#F47560"]}
          textColor="darker"
        />
      </Col>
    </>
  );
};

export default SessionAnalytics;
