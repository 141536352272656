import { Card } from "antd";
import { ResponsiveBar } from "@nivo/bar";

const BarChart = ({
  title,
  indexBy,
  dataKeys,
  data,
  scheme = { scheme: "nivo" },
  textColor = "brighter",
}) => (
  <Card
    title={title}
    hoverable
    className="analytics-card"
    style={{ width: "100%" }}
  >
    {data && data.length > 0 ? (
      <ResponsiveBar
        data={data}
        keys={dataKeys}
        indexBy={indexBy}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        colors={scheme}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [[textColor, 10]] }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    ) : (
      <div className="no-data-avaliable">No Data Avaliable</div>
    )}
  </Card>
);

export default BarChart;
