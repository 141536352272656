import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { PiArrowBendDownRight } from "react-icons/pi";
import { HiExternalLink } from "react-icons/hi";
import moment from "moment";

export const renderTitle = (title, tooltipTitle) => (
  <div>
    {title}
    <span style={{ marginLeft: 5 }}>
      <Tooltip
        title={tooltipTitle}
        color="white"
        overlayInnerStyle={{ color: "black", padding: 15, fontWeight: 300 }}
      >
        <InfoCircleOutlined />
      </Tooltip>
    </span>
  </div>
);

export const calculateBacklinksSpamScore = (backlinks) => {
  const backlinksSpam = {
    "non-toxic": 0,
    toxic: 0,
    "potentially toxic": 0,
  };
  backlinks.forEach((backlink) => {
    const spamScore = backlink.backlink_spam_score;
    if (spamScore >= 60 && spamScore <= 100) {
      backlinksSpam.toxic = backlinksSpam.toxic + 1;
    } else if (spamScore >= 46 && spamScore <= 59) {
      backlinksSpam["potentially toxic"] =
        backlinksSpam["potentially toxic"] + 1;
    } else if (spamScore >= 0 && spamScore <= 45) {
      backlinksSpam["non-toxic"] = backlinksSpam["non-toxic"] + 1;
    }
  });

  return backlinksSpam;
};

export const sourceAndTarget = (backlink) => {
  return (
    <div key={backlink.url_from}>
      {backlink.page_from_title && (
        <p style={{ color: "GrayText" }}>{backlink.page_from_title}</p>
      )}
      <p>
        <span style={{ color: "GrayText" }}>Source: </span> {backlink.url_from}
        <a href={backlink.url_from} target="_blank">
          <HiExternalLink size={18} />
        </a>
      </p>
      <p>
        <span style={{ color: "GrayText" }}>Target: </span> {backlink.url_to}{" "}
        <a href={backlink.url_to} target="_blank">
          <HiExternalLink size={18} />
        </a>
      </p>
      <p>
        <span style={{ color: "GrayText" }}>Rank: </span> {backlink.rank}
      </p>
    </div>
  );
};

export const calculateBacklinksCount = (backlinks) => {
  const domainCount = {};
  const backlinksCount = [];
  backlinks.forEach((backlink) => {
    const { domain_from, domain_from_platform_type } = backlink;

    if (domainCount.hasOwnProperty(domain_from)) {
      domainCount[domain_from].count += 1;
    } else {
      domainCount[domain_from] = {
        count: 1,
        tags: domain_from_platform_type || [],
      };
    }
  });
  const sortedDomainCount = Object.fromEntries(
    Object.entries(domainCount).sort(([, a], [, b]) => b.count - a.count)
  );

  Object.entries(sortedDomainCount).forEach(([key, value]) =>
    backlinksCount.push({
      id: key,
      name: key,
      value: value.count,
      tags: value.tags,
    })
  );

  return backlinksCount;
};

export const calculateBacklinksCountForAnchors = (backlinks) => {
  const anchorsCount = {};
  const backlinksCount = [];
  backlinks.forEach((backlink) => {
    const { anchor, first_seen, last_seen  } = backlink;

    if (anchorsCount.hasOwnProperty(anchor)) {
      anchorsCount[anchor].count += 1;
      anchorsCount[anchor].first_seen = moment.min(moment(anchorsCount[anchor].first_seen), moment(first_seen)).format('YYYY-MM-DD');
      anchorsCount[anchor].last_seen = moment.max(moment(anchorsCount[anchor].last_seen), moment(last_seen)).format('YYYY-MM-DD');
    } else {
      anchorsCount[anchor] = { count : 1, first_seen : moment(first_seen) , last_seen : moment(last_seen) }
    }
  });

  delete anchorsCount['null']
  const sortedAnchorsCount = Object.fromEntries(
    Object.entries(anchorsCount).sort(([, a], [, b]) => b.count - a.count)
  );

  Object.entries(sortedAnchorsCount).forEach(([key, value]) =>
    backlinksCount.push({
      id: key,
      name: key,
      value: value.count,
      first_seen : value.first_seen,
      last_seen: value.last_seen
    })
  );

  return backlinksCount;
};

export const calculateBacklinksCountForTargetPages = (backlinks) => {
  const targetPagesCount = {};
  const backlinksCount = [];
  backlinks.forEach((backlink) => {
    const { url_to  } = backlink;

    if (targetPagesCount.hasOwnProperty(url_to)) {
      targetPagesCount[url_to].count += 1;
    } else {
      targetPagesCount[url_to] = { count : 1 }
    }
  });

  delete targetPagesCount['null']
  const sortedTargetPagesCount = Object.fromEntries(
    Object.entries(targetPagesCount).sort(([, a], [, b]) => b.count - a.count)
  );

  Object.entries(sortedTargetPagesCount).forEach(([key, value]) =>
    backlinksCount.push({
      id: <a>{key}</a>,
      key:  key,
      value: value.count,
    })
  );

  return backlinksCount;
};

export const targetPageUrl = (backlink) => {
  return (
    <div key={backlink.url_to}>
      <p>
        {backlink.url_to}
        <a href={backlink.url_to} target="_blank">
          <HiExternalLink size={18} />
        </a>
      </p>
      <p className="m-0">
        <PiArrowBendDownRight /> {backlink.domain_to}
        <a href={backlink.domain_to} target="_blank">
          <HiExternalLink size={18} />
        </a>
      </p>
    </div>
  );
};

export const sourcePageUrl = (backlink) => {
  return (
    <div key={backlink.domain_from}>
      <p>
        {backlink.domain_from}
        <a href={backlink.domain_from} target="_blank">
          <HiExternalLink size={18} />
        </a>
      </p>
      <p className="m-0">
        <PiArrowBendDownRight /> 
        <a href={backlink.url_from} target="_blank">
        {backlink.url_from}
        </a>
      </p>
    </div>
  );
};


export const tabsLink = { 
     "1" : "overview",
     "2" : "backlinks",
     "3" : "backlinksSpamScore",
     "4" : "targetPages",
     "5" : "referringDomains",
     "6" : "tld",
     "7" : "newandlost",
     "8" : "anchors"
}